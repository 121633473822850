var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("h5", { staticClass: "mb-3" }, [_vm._v("Available Types")]),
        !_vm.loading
          ? _c("AvailableTypes", {
              attrs: { availableTypes: _vm.availableTypes },
              on: { linked: _vm.link },
            })
          : _c("CSpinner", {
              staticClass: "mb-2",
              attrs: { color: "primary", size: "sm" },
            }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("h5", { staticClass: "mt-5 mb-3" }, [_vm._v("Linked Types")]),
        !_vm.loading || _vm.linkedTypes.length
          ? _c("CDataTable", {
              staticClass: "linked-types",
              attrs: {
                hover: "",
                striped: "",
                items: _vm.linkedTypes,
                fields: _vm.fields,
                noItemsView: {
                  noResults: "No filtering results are available!",
                  noItems: "No linked types found!",
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "move_index",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "td",
                          { staticClass: "move-item align-middle" },
                          [
                            _c("CIcon", {
                              staticClass: "align-bottom",
                              attrs: { name: "cil-move", "data-id": item.id },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "type_image_path",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "td",
                          [
                            _c("CImg", {
                              staticClass: "c-avatar-img",
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: item.type_image_path,
                                placeholderColor: "lightgray",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "type",
                    fn: function ({ item }) {
                      return [
                        _c("td", { staticClass: "align-middle" }, [
                          _vm._v(" " + _vm._s(item.type) + " "),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "actions",
                    fn: function ({ item, index }) {
                      return [
                        _c(
                          "td",
                          { staticClass: "align-middle text-center" },
                          [
                            _c(
                              "CButton",
                              {
                                attrs: {
                                  size: "sm",
                                  color: "danger",
                                  shape: "pill",
                                  variant: "ghost",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.unlink(item, index)
                                  },
                                },
                              },
                              [_c("CIcon", { attrs: { name: "cil-trash" } })],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                620199495
              ),
            })
          : _c("CSpinner", {
              staticClass: "mb-3",
              attrs: { color: "primary", size: "sm" },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }