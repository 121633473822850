var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CForm", [
    _c(
      "div",
      { staticClass: "grid-container" },
      _vm._l(_vm.availableTypes, function (rt) {
        return _c(
          "CButton",
          {
            key: rt.id,
            attrs: { color: "info", variant: "outline" },
            on: {
              click: function ($event) {
                return _vm.link(rt.id)
              },
            },
          },
          [
            _c("CIcon", {
              staticClass: "align-bottom",
              attrs: { name: "cil-plus", size: "sm" },
            }),
            _vm._v(" " + _vm._s(rt.type) + " "),
            _c("span", { staticClass: "small" }, [
              _vm._v("(" + _vm._s(rt.restaurant_linked_types_count) + ")"),
            ]),
          ],
          1
        )
      }),
      1
    ),
    !_vm.availableTypes.length
      ? _c("strong", [_vm._v("No available restaurant types found!")])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }